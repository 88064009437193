import {
  createPendingUpload,
  getFileUploadURL,
  verifyMember,
  verifyPendingUpload
} from 'datalayer/api/upload'
import { deleteParam, getPostType } from 'utils/generalUtils'
import { instagram, upload } from 'datalayer/api'

import { reportError } from 'utils/error'

export type UploadActions = {
  init: (slug: string, accessToken?: string) => void
  uploadPosts: (post: any, newMember: boolean) => void
  verifyPendingUploadCode: (code: string) => void
  uploadInstagramPosts: (post: any, newMember: boolean) => void
  verifyMemberBeforeUpload: (newMember: boolean) => Promise<void>
}

export const uploadActions = (set, get): UploadActions => {
  const init = async (slug, accessToken) => {
    const { setView } = get()

    try {
      const { data } = await upload.init(slug)

      set((state) => {
        state.company = data.company
        if (data.translations) {
          state.translations = data.translations
        }
        state.policy = data.policy
        state.incentive = data.incentive
        state.gridPosts = data.posts
        state.uploadTypes = data.uploadTypes
        state.accessToken = accessToken
      })

      if (accessToken) {
        setView('instagram')
      }
    } catch (error) {
      reportError(error)

      deleteParam('accessToken')
    }
  }

  const uploadFileToBucket = async (url: string, { file, fields }) => {
    const data = new FormData()

    Object.entries(fields).map(([key, value]) => {
      data.append(key, value as string)
    })

    data.append('file', file)

    const res = await fetch(url, {
      method: 'POST',
      body: data
    })

    return res
  }

  const verifyMemberBeforeUpload = async (newMember = false) => {
    const { company, email, username, isInstagram } = get()
    try {
      await verifyMember({
        email,
        companySlug: company.slug,
        newMember,
        ...(isInstagram && {
          socialInstagramHandle: username
        })
      })
    } catch (error) {
      reportError(error)
      throw error
    }
  }

  const uploadPosts = async (posts, newMember = false) => {
    const { company, email, name, source } = get()

    try {
      const failed = []

      set((state) => {
        state.progress = {
          total: posts.length,
          count: 0
        }
        state.failed = []
      })

      await new Promise<void>((resolve) => {
        setTimeout(() => {
          resolve()
        }, 1000)
      })

      const medias = []

      for (const post of posts) {
        if (!post?.file?.type) throw new Error('No file type found')

        const type = post.file.type

        const { url, fields, id } = await getFileUploadURL(type)

        const res = await uploadFileToBucket(url, {
          file: post.crop || post.file,
          fields
        })

        if (!res.ok) {
          failed.push(post)
          continue
        }

        medias.push({
          location: fields.key,
          caption: post.caption,
          trim: post.timeData,
          type: getPostType(type)
        })

        set((state) => {
          state.progress = {
            total: posts.length,
            count: state.progress.count + 1
          }
        })
      }

      const input = {
        medias: medias,
        email,
        username: name,
        companySlug: company.slug,
        newMember,
        sourceId: source
      }

      const { _id } = await createPendingUpload(input)

      set((state) => {
        state.pendingUploadId = _id
      })

      if (failed.length > 0) {
        set((state) => {
          state.error = 'upload-error'
          state.view = 'failed-upload'
          state.failed = failed
        })

        return { error: true, failed }
      }

      set((state) => {
        state.view = 'verification-code'
      })

      return { error: false }
    } catch (error) {
      reportError(error)
    }
  }

  const uploadInstagramPosts = async (posts, newMember = false) => {
    const { company, email, username, name } = get()

    try {
      const failed = []

      set((state) => {
        state.progress = {
          total: posts.length,
          count: 0
        }
        state.failed = []
      })

      await new Promise<void>((resolve) => {
        setTimeout(() => {
          resolve()
        }, 1000)
      })

      const medias = []

      for (const post of posts) {
        const instagramRes = await fetch(post.instagramMediaUrl)

        const instagramBlob = await instagramRes.blob()

        if (!instagramBlob.type) throw new Error('No file type found')

        const type = instagramBlob.type

        const { url, fields, id } = await getFileUploadURL(type)

        const res = await uploadFileToBucket(url, {
          file: instagramBlob,
          fields
        })

        set((state) => {
          state.progress = {
            total: posts.length,
            count: state.progress.count + 1
          }
        })

        if (!res.ok) {
          failed.push(post)
          continue
        }

        medias.push({
          location: fields.key,
          caption: post.caption,
          trim: post.timeData,
          type: getPostType(type),
          metadata: {
            fromInstagram: true,
            instagramUrl: post.instagramUrl
          }
        })
      }

      const input = {
        medias: medias,
        email,
        username: name,
        companySlug: company.slug,
        newMember,
        socialInstagramHandle: username
      }

      const { _id, status } = await createPendingUpload(input)

      set((state) => {
        state.pendingUploadId = _id
      })
      ;('')
      if (failed.length > 0) {
        set((state) => {
          state.error = 'upload-error'
          state.view = 'failed-upload'
          state.failed = failed
        })

        return { error: true, failed }
      }

      if (status === 'PROCESSED') {
        set((state) => {
          state.view = 'thanks-for-sharing'
        })
        return { error: false }
      }

      set((state) => {
        state.view = 'verification-code'
      })

      return { error: false }
    } catch (error) {
      reportError(error)
    }
  }

  const verifyPendingUploadCode = async (code) => {
    const { pendingUploadId, company } = get()

    try {
      // if no error is thrown, it succeeded
      await verifyPendingUpload({
        id: pendingUploadId,
        code
      })

      set((state) => {
        state.view = 'uploading'
      })
    } catch (error) {
      throw error
    }
  }

  return {
    init,
    uploadPosts,
    uploadInstagramPosts,
    verifyPendingUploadCode,
    verifyMemberBeforeUpload
  }
}
