import { upload } from 'datalayer/api'

import { getGraphqlError } from 'utils/generalUtils'

export type RemoveActions = {
  isInstagramPostActiveRemove: (post: any) => boolean
  toggleInstagramPostRemove: (post: any) => void
  sendMemberDataRemovalEmail: (email: string) => Promise<void>
  getMemberPosts: (
    input: Parameters<typeof upload.getMemberPostsForDataRemoval>[0]
  ) => Promise<void>
  removePostAsMember: (
    ...props: Parameters<typeof upload.removePostAsMember>
  ) => Promise<void>
}

export const removeActions = (set, get): RemoveActions => {
  const sendMemberDataRemovalEmail = async (email: string) => {
    await upload.sendMemberDataRemovalEmail(email)
  }

  const getMemberPosts = async (
    input: Parameters<typeof upload.getMemberPostsForDataRemoval>[0]
  ) => {
    try {
      const posts = await upload.getMemberPostsForDataRemoval(input)
      set((state) => {
        state.memberPosts = posts
      })
    } catch (error) {
      const err = getGraphqlError(error)

      if (
        !input.instagramAccessToken ||
        (input.instagramAccessToken && err !== 'No members found')
      ) {
        get().setView('remove-auth-options')
        get().setError('custom', 'Could not connect to Instagram')
      }
    }
  }

  const removePostAsMember: RemoveActions['removePostAsMember'] = async (
    ...props
  ) => {
    await upload.removePostAsMember(...props)
  }

  const isInstagramPostActiveRemove = (post) => {
    const { posts } = get()

    return !!posts.find((p) => p._id === post._id)
  }

  const toggleInstagramPostRemove = async (post) => {
    const { posts } = get()

    const isActive = posts.find((p) => p._id === post._id)

    if (isActive) {
      const newPosts = posts.filter((p) => p._id !== post._id)

      set((state) => {
        state.posts = newPosts
      })

      return
    }

    set((state) => {
      state.posts = [
        ...state.posts,
        {
          _id: post._id
        }
      ]
    })
  }

  return {
    toggleInstagramPostRemove,
    isInstagramPostActiveRemove,
    sendMemberDataRemovalEmail,
    getMemberPosts,
    removePostAsMember
  }
}
