import React from 'react'

export const Close = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      className="feather feather-x"
      viewBox="0 0 24 24"
    >
      <path d="M18 6L6 18" />
      <path d="M6 6L18 18" />
    </svg>
  )
}

export const ArrowLeft = () => {
  return (
    <svg
      className="feather feather-arrow-left"
      height="24"
      width="24"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19 12L5 12" />
      <path d="M12 19L5 12 12 5" />
    </svg>
  )
}

export const ChevronLeft = () => {
  return (
    <svg
      className="feather feather-chevron-left"
      height="24"
      width="24"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth="1.5"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15 18L9 12 15 6" />
    </svg>
  )
}

export const ChevronRight = () => {
  return (
    <svg
      className="feather feather-chevron-right"
      height="24"
      width="24"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth="1.5"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9 18L15 12 9 6" />
    </svg>
  )
}

export const Check = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      className="feather feather-check"
      viewBox="0 0 24 24"
    >
      <path d="M20 6L9 17 4 12" />
    </svg>
  )
}

export const Alert = () => {
  return (
    <svg
      className="feather feather-alert-triangle"
      height="24"
      width="24"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z" />
      <path d="M12 9L12 13" />
      <path d="M12 17L12.01 17" />
    </svg>
  )
}

export const CircleLoader = () => {
  return (
    <svg
      className="rotating"
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      >
        <g
          stroke="currentColor"
          strokeWidth="2"
          transform="translate(2.000000, 2.000000)"
        >
          <path d="M12,24 C18.627417,24 24,18.627417 24,12 C24,5.372583 18.627417,0 12,0 C5.372583,0 0,5.372583 0,12" />
        </g>
      </g>
    </svg>
  )
}

export const QuestionMark = () => {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        fill="#000"
        fillRule="evenodd"
      />
      <path
        d="M11.1332 9.50073C10.8567 9.97879 10.245 10.1421 9.7669 9.86561C9.28884 9.58906 9.12547 8.97733 9.40202 8.49927C9.91922 7.60518 10.8884 7 12.0001 7C13.6569 7 15.0001 8.34315 15.0001 10C15 11.0977 14.3267 11.994 13.5001 12.5C12.8361 12.9065 13.2501 14 11.9974 14C11.4452 14 10.9974 13.5523 10.9974 13C10.9974 12.9649 10.9992 12.9302 11.0028 12.896C11.0305 12.3672 11.2663 11.9368 11.5231 11.6239C11.7943 11.2935 12.1508 11.0237 12.4234 10.8616C13.9401 9.96 12.0902 8.1154 11.1332 9.50073ZM11.9974 15C11.4452 15 10.9974 15.4477 10.9974 16C10.9974 16.5523 11.4452 17 11.9974 17C12.5497 17 12.9974 16.5523 12.9974 16C12.9974 15.4477 12.5497 15 11.9974 15Z"
        fill="#000"
        fillRule="evenodd"
      />
    </svg>
  )
}

export const Crop = () => {
  return (
    <svg
      height="32"
      width="32"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4013 2H30V14.5999M14.5987 30H2V17.4002"
        stroke="currentColor"
        strokeWidth="3"
      />
    </svg>
  )
}

export const Zoom = () => {
  return (
    <svg
      height="32"
      width="32"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.5849 29.3989L24.6825 22.4965C26.891 19.7294 27.9563 16.2218 27.6598 12.6939C27.3633 9.16596 25.7274 5.88541 23.088 3.52577C20.4486 1.16613 17.006 -0.0935268 13.4671 0.00541693C9.92807 0.104361 6.56124 1.5544 4.05782 4.05782C1.5544 6.56124 0.104361 9.92807 0.00541693 13.4671C-0.0935268 17.006 1.16613 20.4486 3.52577 23.088C5.88541 25.7274 9.16596 27.3633 12.6939 27.6598C16.2218 27.9563 19.7294 26.891 22.4965 24.6825L29.3989 31.5849C29.6921 31.8581 30.0799 32.0068 30.4806 31.9998C30.8813 31.9927 31.2636 31.8304 31.547 31.547C31.8304 31.2636 31.9927 30.8813 31.9998 30.4806C32.0068 30.0799 31.8581 29.6921 31.5849 29.3989ZM13.8907 24.6145C11.7698 24.6145 9.69644 23.9855 7.93293 22.8072C6.16942 21.6288 4.79493 19.954 3.98328 17.9945C3.17162 16.035 2.95926 13.8788 3.37304 11.7986C3.78681 9.71842 4.80815 7.80763 6.30789 6.30789C7.80763 4.80815 9.71842 3.78681 11.7986 3.37304C13.8788 2.95926 16.035 3.17162 17.9945 3.98328C19.954 4.79493 21.6288 6.16942 22.8072 7.93293C23.9855 9.69644 24.6145 11.7698 24.6145 13.8907C24.6112 16.7338 23.4803 19.4595 21.4699 21.4699C19.4595 23.4803 16.7338 24.6112 13.8907 24.6145ZM18.9432 12.344H15.4374V8.83819C15.4374 8.42798 15.2745 8.03457 14.9844 7.74451C14.6943 7.45445 14.3009 7.2915 13.8907 7.2915C13.4805 7.2915 13.0871 7.45445 12.797 7.74451C12.507 8.03457 12.344 8.42798 12.344 8.83819V12.344H8.83819C8.42798 12.344 8.03457 12.507 7.74451 12.797C7.45445 13.0871 7.2915 13.4805 7.2915 13.8907C7.2915 14.3009 7.45445 14.6943 7.74451 14.9844C8.03457 15.2745 8.42798 15.4374 8.83819 15.4374H12.344V18.9432C12.344 19.3535 12.507 19.7469 12.797 20.0369C13.0871 20.327 13.4805 20.4899 13.8907 20.4899C14.3009 20.4899 14.6943 20.327 14.9844 20.0369C15.2745 19.7469 15.4374 19.3535 15.4374 18.9432V15.4374H18.9432C19.3535 15.4374 19.7469 15.2745 20.0369 14.9844C20.327 14.6943 20.4899 14.3009 20.4899 13.8907C20.4899 13.4805 20.327 13.0871 20.0369 12.797C19.7469 12.507 19.3535 12.344 18.9432 12.344Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const Play = () => {
  return (
    <svg
      className="svg-inline--fa fa-play fa-w-14"
      viewBox="0 0 448 512"
      xmlns="http://www.w3.org/2000/svg"
      data-icon="play"
      data-prefix="fas"
    >
      <path
        d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"
        fill="currentColor"
      />
    </svg>
  )
}

export const Trash = () => {
  return (
    <svg
      className="feather feather-trash-2"
      height="24"
      width="24"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3 6L5 6 21 6" />
      <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
      <path d="M10 11L10 17" />
      <path d="M14 11L14 17" />
    </svg>
  )
}

export const Search = () => {
  return (
    <svg
      className="feather feather-search"
      height="24"
      width="24"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth="1.5"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="11" cy="11" r="8" />
      <path d="M21 21L16.65 16.65" />
    </svg>
  )
}
export const Smiley = () => {
  return (
    <svg
      className="feather feather-smile"
      height="24"
      width="24"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="10" />
      <path d="M8 14s1.5 2 4 2 4-2 4-2" />
      <path d="M9 9L9.01 9" />
      <path d="M15 9L15.01 9" />
    </svg>
  )
}
export const Carousel = () => {
  return (
    <svg
      className="_8-yf5"
      height="22"
      width="22"
      fill="currentColor"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M34.8 29.7V11c0-2.9-2.3-5.2-5.2-5.2H11c-2.9 0-5.2 2.3-5.2 5.2v18.7c0 2.9 2.3 5.2 5.2 5.2h18.7c2.8-.1 5.1-2.4 5.1-5.2zM39.2 15v16.1c0 4.5-3.7 8.2-8.2 8.2H14.9c-.6 0-.9.7-.5 1.1 1 1.1 2.4 1.8 4.1 1.8h13.4c5.7 0 10.3-4.6 10.3-10.3V18.5c0-1.6-.7-3.1-1.8-4.1-.5-.4-1.2 0-1.2.6z" />
    </svg>
  )
}
